/**
 * ResearchSpace
 * Copyright (C) 2020, © Trustees of the British Museum
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import { Rdf } from 'platform/api/rdf';

let currentResource: Rdf.Iri;

/**
 * Returns current resource IRI.
 */
export function getCurrentResource(): Rdf.Iri {
  return currentResource;
}

/**
 * For testing purpose only
 */
export function __unsafe__setCurrentResource(resource: Rdf.Iri) {
  currentResource = resource;
}
