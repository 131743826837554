/**
 * ResearchSpace
 * Copyright (C) 2020, © Trustees of the British Museum
 * Copyright (C) 2015-2019, metaphacts GmbH
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.

 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { serializerFor, deserializerFor } from '../JsonCore';
import * as maybe from 'data.maybe';

/**
 * Serializers and deserializers for data.maybe.
 */
export function registerSerializersAndDeserializers() {
  serializerFor({
    name: 'Data.Maybe',
    predicate: function (obj) {
      return obj instanceof maybe;
    },
    serializer: function (obj: Data.Maybe<any>) {
      if (obj.isNothing) {
        return {};
      } else {
        return {
          value: obj.get(),
        };
      }
    },
  });
  deserializerFor({
    name: 'Data.Maybe',
    deserializer: function (object: any) {
      if (object.value) {
        return maybe.Just(object.value);
      } else {
        return maybe.Nothing();
      }
    },
  });
}
